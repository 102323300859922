<template>
	<div>
		<div class="js-copy js-content pd_body">
			<topHeader :type="'white'"></topHeader>
			<acRefund
				:item="item"
				v-if="showTips"
				@close="tipClose()"
				@refundSure="refundSure"
			>
			</acRefund>
			<div class="ac_organ">
				<div class="ac_organ_header">
					<img src="https://zy.metaera.media/assets/images/active/43.png" />
					<span>我參加的活動</span>
				</div>
				<div class="ac_organ_status">
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 0 }"
						@click="selStatusClick(0)"
					>
						全部
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 1 }"
						@click="selStatusClick(1)"
					>
						已报名活动
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 2 }"
						@click="selStatusClick(2)"
					>
						已结束活动
					</div>
					<div
						class="ac_organ_status_item"
						:class="{ ac_organ_status_item_sel: selStatusIndex == 3 }"
						@click="selStatusClick(3)"
					>
						退费中
					</div>
				</div>
				<div class="ac_organ_list">
					<div class="ac_organ_list_header" v-if="localLanguage == 2">
						{{
							selStatusIndex == 0
								? acList.length
								: selStatusIndex == 1
								? acStartList.length
								: selStatusIndex == 2
								? acEndList.length
								: acBackList.length
						}}
						events in total
					</div>
					<div class="ac_organ_list_header" v-if="localLanguage != 2">
						共
						{{
							selStatusIndex == 0
								? acList.length
								: selStatusIndex == 1
								? acStartList.length
								: selStatusIndex == 2
								? acEndList.length
								: acBackList.length
						}}
						场活动</div
					>
					<div class="ac_organ_list_content">
						<div
							v-for="(item, index) in selStatusIndex == 0
								? acList
								: selStatusIndex == 1
								? acStartList
								: selStatusIndex == 2
								? acEndList
								: acBackList"
							:key="index"
						>
							<acItem
								:item="item"
								:type="1"
								@gotoDetail="gotoDetail"
								@refund="refund"
								@cancelOrder="cancelOrder"
								@continueOrder="continueOrder"
							>
							</acItem>
						</div>
					</div>
				</div>
			</div>
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
import {
	acitvityMyLists,
	orderUpdate,
	orderCheck,
	orderCancel,
} from "@/api/home";
import topHeader from "../../components/pc/topHeader.vue";
import bottom from "../../components/pc/bottom.vue";
import acItem from "./ac_item.vue";
import acRefund from "./ac_refund.vue";
import { getUserId } from "@/utils/auth";
import { getToken } from "@/utils/auth";
import { loadStripe } from "@stripe/stripe-js";
import { eventBus } from "../../components/pc/leftTab";
export default {
	name: "",
	components: {
		topHeader,
		bottom,
		acItem,
		acRefund,
	},
	data() {
		return {
			selStatusIndex: 0,
			page: 1,
			showTips: false,
			size: 25,
			acList: [],
			acStartList: [],
			acEndList: [],
			acBackList: [],
			item: {},
			localLanguage: 0,
		};
	},
	created() {
		const hasToken = getToken();
		console.log(hasToken);
		if (hasToken) {
			this.getACList({ userid: getUserId() });
		} else {
			this.$router.push({ path: "/" });
		}
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	methods: {
		showBannerDetail(id) {
			this.$router.push({
				path: "/ac_create",
			});
		},
		gotoDetail(item) {
			this.$router.push({
				path: "/ac_detail",
				query: { id: item.id },
			});
		},

		cancelOrder(item) {
			this.loading = true;
			orderCancel({ id: item.ticket_id })
				.then((res) => {
					this.loading = false;
					if (res.code == 200) {
						this.$message({ message: res.message, type: "success" });
						this.getACList({ userid: getUserId() });
					} else {
						this.getACList({ userid: getUserId() });
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.loading = false;
					this.getACList({ userid: getUserId() });
					if (type == 1) {
						this.$message({ message: error.message, type: "error" });
					}
				});
		},

		continueOrder(item) {
			this.buyTicket(item, 1);
		},
		async buyTicket(item, type) {
			const stripe = await loadStripe(
				"pk_live_51PYrHdEQq0jGyNkS55zMD21yxNNrl2pZU9nlo2IxhgKcbEgVWQGRyjWhB3pEzzgrDwTxohG54aairByrxCqIg32g00gWroSP0b"
			); // 替换为你的Publishable Key

			this.loading = true;
			orderCheck({ id: item.ticket_id })
				.then((res) => {
					this.loading = false;
					if (res.code == 200) {
						stripe.redirectToCheckout({
							sessionId: res.data.ticket_session_id,
						});
						this.getACList({ userid: getUserId() });
					} else {
						this.getACList({ userid: getUserId() });
						if (type == 1) {
							this.$message({ message: res.message, type: "error" });
						}
					}
				})
				.catch((error) => {
					this.loading = false;
					this.getACList({ userid: getUserId() });
					if (type == 1) {
						this.$message({ message: error.message, type: "error" });
					}
				});
		},
		refund(item) {
			this.item = item;
			this.showTips = true;
		},
		tipClose() {
			this.showTips = false;
		},
		refundSure(data) {
			this.loading = true;
			orderUpdate(data)
				.then((res) => {
					this.loading = false;
					if (res.code == 200) {
						this.showTips = false;
						this.getACList({ userid: getUserId() });
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.loading = false;
					this.$message({ message: error.message, type: "error" });
				});
		},
		selStatusClick(index) {
			this.selStatusIndex = index;
		},
		getACList(obj) {
			acitvityMyLists(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						this.acList = res.data;
						this.acStartList = [];
						this.acEndList = [];
						this.acBackList = [];
						for (let i = 0; i < this.acList.length; i++) {
							const item = this.acList[i];
							if (item.ticket_status == 2 || item.ticket_status == 3) {
								this.acBackList.push(item);
							} else {
								if (item.startStatus == 2) {
									this.acEndList.push(item);
								} else {
									this.acStartList.push(item);
								}
							}
						}

						console.log(this.acStartList);
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$message({ message: error.message, type: "error" });
				});
		},
	},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: white;
}
.ac_organ {
	margin-left: 10%;
	width: 80%;
}
</style>

<style scoped>
.ac_organ_header {
	margin-top: 130px;
	display: flex;
}

.ac_organ_header img {
	width: 24px;
	height: 24px;
}

.ac_organ_header span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 22px;
	font-weight: normal;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
</style>

<!-- ac_organ_status -->
<style scoped>
.ac_organ_status {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	height: 50px;
}

.ac_organ_status_item_sel {
	/* 333 */
	background: #333333 !important;
	color: #ffffff !important;
}

.ac_organ_status_item {
	width: 23%;
	border-radius: 10px;
	opacity: 1;
	/* 纯白 */
	background: #ffffff;

	box-sizing: border-box;
	border: 1px solid #ececec;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	/* display: flex; */
	align-content: center;
	text-align: center;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
	display: grid;
}
</style>

<!-- ac_organ_list -->
<style scoped>
.ac_organ_list {
	margin-top: 40px;
	min-height: 80vh;
}
.ac_organ_list_header {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;

	/* 三级颜色 */
	color: #7a8291;
}

.ac_organ_list_content {
	margin-top: 20px;
	opacity: 1;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.ac_organ {
		margin-left: 5%;
		width: 90%;
	}
	.ac_organ_header {
		margin-top: 90px;
	}
	.ac_organ_list {
		margin-top: 20px;
	}
}
</style>
